"use client";
import { Container } from "components/layout/Container";
import { Logo } from "components/shared/Logo";
import { PaymentIcons } from "components/shared/PaymentIcons";
import SupportIcon from "public/img/icons/svg/support.svg";
import PhoneIcon from "public/img/icons/svg/phone.svg";
import FacebookIcon from "public/img/icons/svg/facebook.svg";
import InstagramIcon from "public/img/icons/svg/instagram.svg";

import { APP_VERSION, baseUrl } from "@/const";
import Link from "next/link";
import { FooterMenu } from "./FooterMenu";
import styles from "./SiteFooter.module.scss";
import { ResponsiveImage } from "../../shared/ResponsiveImage";
import { useAppContext } from "@/helpers/contexts/AppContext";
import { useInteraction } from "@/helpers/useInteraction";
import { usePathname } from "next/navigation";
import { SpriteIcon } from "@/components/UI/SpriteIcon";

export const SiteFooter = ({ isBlogPage }: { isBlogPage?: boolean }) => {
  const contextData = useAppContext();
  const pathname = usePathname();
  const { isInteracted } = useInteraction();
  if (!contextData) return null;

  const {
    footer: { description, bottomMenu },
    legalInfo: { organization },
  } = contextData;

  return (
    <footer className={styles.footer} data-version={APP_VERSION}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.about}>
            <div className={styles.aboutLeft}>
              <Logo color="black" className={styles.logo} />
              <div className={styles.companyDescription}>
                <p>
                  <strong>{description.heading}</strong>
                </p>
                <p>{description.content}</p>
              </div>
            </div>

            <div className={styles.aboutRight}>
              {organization?.contacts?.socials && (
                <div className={styles.socialsWrap}>
                  <span>Follow Us:</span>
                  <ul className={styles.socialsList}>
                    {organization.contacts.socials?.instagram && (
                      <li key={"insta"} className={styles.socialsItem}>
                        <a
                          href={organization.contacts.socials.instagram}
                          target="_blank"
                          rel="noreferrer noopener"
                          className={styles.socialsLink}
                        >
                          <SpriteIcon icon={InstagramIcon} />
                          <span>instagram</span>
                        </a>
                      </li>
                    )}
                    {organization.contacts.socials?.facebook && (
                      <li key={"fb"} className={styles.socialsItem}>
                        <a
                          href={organization.contacts.socials.facebook}
                          target="_blank"
                          rel="noreferrer noopener"
                          className={styles.socialsLink}
                        >
                          <SpriteIcon icon={FacebookIcon} />
                          <span>facebook</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}

              <div className={styles.legal}>
                <div className={styles.legalInfo}>
                  <ResponsiveImage
                    src="/img/components/footer/legalinfo.svg"
                    width={392}
                    height={29}
                    layout="intrinsic"
                    alt="Legal info"
                  />
                </div>
                {!!bottomMenu?.length && (
                  <div className={styles.legalLinks}>
                    {bottomMenu.map(({ name, href, rel }) => (
                      <span key={href}>
                        <Link href={href} rel={rel} prefetch={false}>
                          {name}
                        </Link>
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.menu}>
            <FooterMenu isBlogPage={isBlogPage} />
          </div>

          <div className={styles.contacts}>
            <div className={styles.contactsTitle}>Contacts</div>
            <p className={styles.contactsText}>
              Feel free to get in touch with us via phone or send us a message
            </p>
            {organization.contacts?.phones[0] && (
              <p className={styles.contactsItem}>
                <span className={styles.contactIcon}>
                  <SpriteIcon icon={PhoneIcon} />
                </span>
                <a href={`tel:+${organization.contacts.phones[0].number.replace(/\D/g, "")}`}>
                  {organization.contacts.phones[0].number}
                </a>
              </p>
            )}
            {organization?.email && (
              <p className={styles.contactsItem}>
                <span className={styles.contactIcon}>
                  <SpriteIcon icon={SupportIcon} />
                </span>
                <a href={`mailto:${organization.email}`}>{organization.email}</a>
              </p>
            )}
          </div>
        </div>
        <hr />
        <div className={styles.bottom}>
          <PaymentIcons />
          <div className={styles.dmcaIcon}>
            <a
              href={`https://www.dmca.com/Protection/Status.aspx?ID=139cd0aa-f936-4876-8283-3177983f947c&refurl=https://${
                baseUrl + (pathname.length > 1 ? pathname : "")
              }`}
              title="DMCA.com Protection Status"
              className="dmca-badge"
              target="_blank"
              suppressHydrationWarning
              rel="noreferrer"
            >
              {isInteracted && (
                <img
                  style={{ display: "none" }}
                  src="https://images.dmca.com/Badges/DMCA_logo-std-btn120w.png?ID=139cd0aa-f936-4876-8283-3177983f947c"
                  alt="DMCA.com Protection Status"
                />
              )}
              <ResponsiveImage
                layout="intrinsic"
                src="/img/components/footer/dmca.svg"
                height={37}
                width={80}
                alt="DMCA Protected"
              />
            </a>
          </div>

          <small className={styles.copyright}>
            &copy;{" "}
            <Link href="/" prefetch={false}>
              StudyCrumb.com
            </Link>{" "}
            {new Date().getFullYear()}. All rights reserved.
          </small>
        </div>
      </Container>
    </footer>
  );
};
