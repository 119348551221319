import cn from "classnames";
import { upperFirst } from "lodash";
import styles from "./Burger.module.scss";

export type BurgerProps = {
  color?: "white" | "black";
  expanded?: boolean;
  className?: string;
  onToggle: () => void;
};

export const Burger = ({ color = "white", expanded = false, onToggle, className }: BurgerProps) => {
  return (
    <div
      className={cn(
        styles.hamburger,
        styles[`color${upperFirst(color)}`],
        {
          [styles.active]: expanded,
        },
        className
      )}
      onClick={onToggle}
    >
      <div className={styles.box}>
        <div className={styles.inner}></div>
      </div>
    </div>
  );
};
