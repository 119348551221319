"use client";
import Image from "next/legacy/image";
import { CSSProperties, useEffect, useState } from "react";
import useDimensions from "react-cool-dimensions";

export type ResponsiveImageProps = {
  src: string;
  src2x?: string;
  srcTab?: string;
  srcMob?: string;
  alt?: string;
  title?: string;
  width?: number;
  height?: number;
  className?: string;
  imageClassName?: string;
  style?: CSSProperties;
  breakpoints?: Record<string, number>;
  objectPosition?: string;
  objectFit?: "fill" | "contain" | "cover" | "scale-down" | "none";
  layout?: "intrinsic" | "fixed" | "responsive" | "fill";
  relative?: boolean;
  priority?: boolean;
  quality?: number;
};

export const ResponsiveImage = ({
  src,
  src2x,
  srcTab,
  srcMob,
  className,
  alt,
  title,
  objectFit = "contain",
  objectPosition = "center center",
  width,
  height,
  layout = "fill",
  imageClassName,
  breakpoints = {
    xs: 0,
    sm: 576,
    md: 920,
  },
  relative,
  style,
  priority,
  quality = 90,
}: ResponsiveImageProps) => {
  const [imgSrc, setImgSrc] = useState(src);

  const { observe, entry } = useDimensions({
    breakpoints,
    onResize: ({ currentBreakpoint }) => {
      if (currentBreakpoint === "xs" && (srcMob || srcTab)) {
        setImgSrc((srcMob || srcTab) as string);
      } else if (currentBreakpoint === "sm" && srcTab) {
        setImgSrc(srcTab);
      } else if (window.devicePixelRatio > 1 && src2x) {
        setImgSrc(src2x);
      } else {
        setImgSrc(src);
      }
    },
  });

  useEffect(() => {
    const windowWidth = window.innerWidth;

    if (windowWidth <= breakpoints.xs && (srcMob || srcTab)) {
      setImgSrc((srcMob || srcTab) as string);
    } else if (windowWidth <= breakpoints.sm && srcTab) {
      setImgSrc(srcTab);
    } else if (window.devicePixelRatio > 1 && src2x) {
      setImgSrc(src2x);
    } else {
      setImgSrc(src);
    }
  }, [src, srcMob, srcTab, src2x, breakpoints]);

  const relativeStyle: CSSProperties = relative ? { position: "relative" } : {};

  return (
    <div className={className} ref={observe} style={{ ...style, ...relativeStyle }}>
      <Image
        src={imgSrc}
        width={width}
        height={height}
        layout={layout}
        objectFit={objectFit}
        objectPosition={objectPosition}
        alt={alt || "Illustration"}
        title={title}
        className={imageClassName}
        priority={priority}
        quality={quality}
        suppressHydrationWarning
      />
    </div>
  );
};

export default ResponsiveImage;
