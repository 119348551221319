import cn from "classnames";
import Image from "next/legacy/image";
import styles from "./PaymentIcons.module.scss";
import { Popover } from "components/UI/Tooltips/Popover";
import { upperFirst } from "lodash";

const icons = [
  {
    slug: "visa",
    alt: "Visa",
  },
  {
    slug: "jcb",
    alt: "JCB",
  },
  {
    slug: "mastercard",
    alt: "Mastercard",
  },
  {
    slug: "union-pay",
    alt: "Union Pay",
  },
  {
    slug: "amex",
    alt: "American Express",
  },
  {
    slug: "diners-club",
    alt: "Diners Club",
  },
  {
    slug: "discover",
    alt: "Discover",
  },
  {
    slug: "google-pay",
    alt: "Google Pay",
  },
  {
    slug: "apple-pay",
    alt: "Apple Pay",
  },
];

type PaymentIconsProps = {
  size?: "small" | "big";
  className?: string;
};

export const PaymentIcons = ({ size = "small", className }: PaymentIconsProps) => (
  <ul className={cn(styles.paymentIcons, styles[`size${upperFirst(size)}`], className)}>
    {icons.map((el) => (
      <li key={el.slug} className={styles.paymentIcon}>
        <Popover caption={el.alt}>
          <Image
            width={size === "big" ? 80 : 55}
            height={size === "big" ? 56 : 40}
            src={`/img/misc/payments/${el.slug}.svg`}
            alt={el.alt}
          />
        </Popover>
      </li>
    ))}
  </ul>
);
