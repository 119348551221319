"use client";
import { useCallback, useEffect, useState } from "react";
import { SpriteIcon } from "@/components/UI/SpriteIcon";

export const LazySvgSpriteIcon = ({ name }: { name: string }) => {
  const [icon, setIcon] = useState<any>(null);

  const loadIcon = useCallback(async () => {
    try {
      const icon = (await import(`public/img/icons/svg/${name}.svg`)).default;

      setIcon(icon);
    } catch (err) {}
  }, [name]);

  useEffect(() => {
    loadIcon();
  }, [loadIcon]);

  return icon ? <SpriteIcon icon={icon} width={36} height={36} /> : null;
};
