import cn from "classnames";

export type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  tag?: "div" | "nav";
  divider?: "top" | "bottom";
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Container = ({ children, className, tag = "div", divider, ...props }: ContainerProps) => {
  const Tag = tag;

  return (
    <Tag
      className={cn(
        "sc-container",
        {
          [`sc-container--divider-${divider}`]: divider,
        },
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};
