import cn from "classnames";
import { Container } from "components/layout/Container";
import isCurrentPage from "helpers/isCurrentPage";
import Image from "next/legacy/image";
import Link from "next/link";
import { useInteraction } from "@/helpers/useInteraction";
import { HeaderMenuData } from "@/types/strapi/global/headerMenu";
import { usePathname } from "next/navigation";
import { SpriteIcon } from "@/components/UI/SpriteIcon";
import angleDownIcon from "public/img/icons/svg/angle-down.svg";
import { IconImage } from "@/components/UI/IconImage";
import { SLUG_IMG_MAP } from "@/helpers/menuIcons";

type HeaderDesktopMenuProps = {
  menuItems: HeaderMenuData["items"];
  headerSticky: boolean;
  headerColor?: "white" | "black";
  setDesktopMenuItemOpen: (value: boolean) => void;
  isBlogPage?: boolean;
};

// TODO: ADJUST CONTENT IN STRAPI AND THEN REMOVE IT

export const HeaderDesktopMenu = ({
  menuItems,
  headerSticky,
  headerColor,
  setDesktopMenuItemOpen,
  isBlogPage,
}: HeaderDesktopMenuProps) => {
  const pathname = usePathname();

  const { isInteracted } = useInteraction();

  const handleClick = (event: React.MouseEvent) => {
    const target = event.currentTarget;
    if (!event.currentTarget) return;
    const list = [...(target?.parentNode?.children || [])];
    const current = event.currentTarget;
    const isActive = current.classList.contains("is-active");

    let activeItemFound = false;

    list.forEach((item) => {
      if (item === current && !isActive) {
        activeItemFound = true;
        current.classList.add("is-active");
      } else {
        item.classList.remove("is-active");
      }
    });

    setDesktopMenuItemOpen(activeItemFound);
  };

  return (
    <nav
      className={cn("sc-header-desk-menu", {
        ["sc-header-desk-menu--sticky"]: headerSticky,
        ["sc-header-desk-menu--black"]: headerColor === "black",
      })}
    >
      <ul className="sc-header-desk-menu__main-list">
        {menuItems.map(({ name, id, href, children }) => (
          <li
            className={cn("sc-header-desk-menu__main-item", {
              ["sc-header-desk-menu__main-item--current"]: isCurrentPage(
                pathname,
                href,
                isBlogPage,
                children
              ),
            })}
            key={id}
            onClick={children && handleClick}
          >
            {children && !!children.length ? (
              <>
                <span className="sc-header-desk-menu__main-link">
                  {name}
                  <SpriteIcon icon={angleDownIcon} />
                </span>
                <div className={cn("sc-header-desk-menu__children")}>
                  <Container>
                    <ul
                      className={cn("sc-header-desk-menu__children-list", {
                        ["sc-header-desk-menu__children-list--mod-4"]: children.length === 4,
                      })}
                    >
                      {children.map(({ name, id, href, icon }) => (
                        <li
                          key={id}
                          className={cn("sc-header-desk-menu__children-item", {
                            ["sc-header-desk-menu__children-item--current"]: isCurrentPage(
                              pathname,
                              String(href)
                            ),
                            isBlogPage,
                          })}
                        >
                          <Link
                            href={href.trim()}
                            prefetch={false}
                            className="sc-header-desk-menu__children-link"
                          >
                            <div className="sc-header-desk-menu__children-item-icon">
                              {isInteracted &&
                                (SLUG_IMG_MAP[href.trim()] ? (
                                  <IconImage size={36} icon={SLUG_IMG_MAP[href.trim()]} />
                                ) : (
                                  <Image src={icon.url} width={36} height={36} alt={`${name} icon`} />
                                ))}
                            </div>
                            {name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Container>
                </div>
              </>
            ) : (
              <Link href={String(href)} prefetch={false} className="sc-header-desk-menu__main-link">
                {name}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
