"use client";
import { useEffect, useState, useMemo } from "react";
import cn from "classnames";
import { Logo } from "components/shared/Logo";
import { Button } from "components/UI/Buttons/Button";
import { Burger } from "components/UI/Buttons/Burger";
import { HeaderDesktopMenu } from "components/layout/Header/HeaderDesktopMenu";
import { Container } from "components/layout/Container";
import { useAppContext } from "@/helpers/contexts/AppContext";
import { orderLink, ordersLink } from "@/const";
import dynamic from "next/dynamic";

const HeaderBurgerMenu = dynamic(() => import("@/components/layout/Header/HeaderBurgerMenu"), { ssr: false });

export type SiteHeaderColor = "white" | "black";

type SiteHeaderProps = {
  color?: SiteHeaderColor;
  isBlogPage?: boolean;
};

export const SiteHeader = ({ color = "black", isBlogPage }: SiteHeaderProps) => {
  const contextData = useAppContext();

  const [isSticky, setSticky] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [desktopMenuItemOpen, setDesktopMenuItemOpen] = useState(false);

  const handleScroll = () => {
    setSticky(window.scrollY > 30);
  };

  const toggleBurger = () => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    document.body.classList[isExpanded ? "add" : "remove"]("overflowHidden");
  }, [isExpanded]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerColor = useMemo(() => {
    if (color === "black" || isSticky || isExpanded || desktopMenuItemOpen) return "black";
    return "white";
  }, [isSticky, isExpanded, color, desktopMenuItemOpen]);

  if (!contextData) return null;

  const { headerMenu } = contextData;

  return (
    <header
      className={cn("sc-header", `sc-header--color-${color}`, {
        ["sc-header--sticky"]: isSticky || isExpanded || desktopMenuItemOpen,
      })}
    >
      <Container className="sc-header__main">
        <Logo color={headerColor} className="sc-header__logo" />
        <div className="sc-header__content">
          <HeaderDesktopMenu
            setDesktopMenuItemOpen={setDesktopMenuItemOpen}
            headerColor={headerColor}
            headerSticky={isSticky}
            menuItems={headerMenu.items as any}
            isBlogPage={isBlogPage}
          />
          <div className="sc-header__buttons">
            <Button
              type="primary"
              icon="user"
              outline
              href={ordersLink}
              className="sc-header__button"
              prioritizeLoad
            >
              Dashboard
            </Button>
            <Button
              icon="shopping-cart"
              outline
              href={orderLink}
              className="sc-header__button"
              prioritizeLoad
            >
              Order now
            </Button>
          </div>
          <Burger
            color={headerColor}
            className="sc-header__burger"
            expanded={isExpanded}
            onToggle={toggleBurger}
          />
        </div>
        {isExpanded && <HeaderBurgerMenu menuItems={headerMenu.items as any} isBlogPage={isBlogPage} />}
      </Container>
    </header>
  );
};
