import cn from "classnames";
import Image from "next/image";
import Link from "next/link";

import styles from "./Logo.module.scss";

export type LogoProps = {
  color?: "black" | "white";
  className?: string;
};

export const Logo = ({ color = "black", className }: LogoProps) => {
  return (
    <Link href={`/`} prefetch={false} className={cn(styles.logo, className)}>
      <Image src={`/img/logo/logo-${color}.svg`} height={48} width={90} alt="StudyCrumb" priority />
    </Link>
  );
};
