import cn from "classnames";
import styles from "./ButtonSpinner.module.scss";

export const ButtonSpinner = ({ className }: { className?: string }) => (
  <div className={cn(styles.spinner, className)}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
