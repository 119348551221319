import { useEffect, useRef } from "react";

export const useEventListener = <T extends keyof GlobalEventHandlersEventMap, E extends Event>(
  eventName: T,
  handler: (event: E) => void
) => {
  const saveHandler = useRef<(event: E) => void>();

  useEffect(() => {
    saveHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: any) => {
      saveHandler?.current?.(event);
    };

    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName]);
};
