import Image, { ImageProps } from "next/image";
import { IconImageType } from "@/types/icon";

type IconImageProps = Omit<ImageProps, "width" | "height" | "src" | "alt"> & {
  size: number;
  icon: IconImageType;
  alt?: string;
};

export const IconImage = ({ size, icon, alt, ...props }: IconImageProps) => (
  <Image
    src={`/img/icons/${icon}.png`}
    width={size}
    height={size}
    quality={100}
    unoptimized
    alt={alt || "Illustration"}
    {...props}
  />
);
