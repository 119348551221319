"use client";
import type { MetaMetaData, MetaPageType, MetaArticleData, MetaAuthorData } from "types/meta";
import { useAppContext } from "@/helpers/contexts/AppContext";
import { omit } from "lodash";
import { getMetaTitleDescription } from "@/helpers/getMetaTitleDescription";
import { baseUrl } from "@/const";
import { usePathname } from "next/navigation";

type StructuredDataProps = {
  metaData: MetaMetaData;
  pageType: MetaPageType;
  articleData?: MetaArticleData;
  metaReviews?: {
    ratingValue: number;
    reviewCount: number;
  };
  articleReviews?: {
    ratingValue: number;
    reviewCount: number;
  };
  metaThumbnail?: string;
  authorData?: MetaAuthorData;
  extraFields?: Record<string, any>;
};

export const StructuredData = ({
  metaData,
  pageType,
  articleData,
  metaReviews,
  metaThumbnail,
  authorData,
  extraFields = {},
  articleReviews,
}: StructuredDataProps) => {
  const url = usePathname();
  const isWebPage = ["WebPage", "CollectionPage", "AboutPage", "PrivacyPolicy"].includes(pageType);
  const isArticle = pageType === "Article";
  const isBlog = pageType === "Blog";
  const isContact = pageType === "ContactPage";
  const isAuthor = pageType === "Person";
  const isPricePage = pageType === "Service";

  const contextData = useAppContext();

  if (!contextData) return null;

  const { legalInfo } = contextData;

  const { organization, website } = legalInfo;

  const { pageTitle, pageDescription } = getMetaTitleDescription(url, metaData.title, metaData.description);

  const fullUrl = `https://${baseUrl + url}`;

  const canonicalUrl = fullUrl.split("?")[0];

  const organizationData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    id: "https://studycrumb.com/#organization",
    name: organization.name,
    url: organization.url,
    sameAs: Object.values(omit(organization.contacts?.socials || {}, "id")),
    address: {
      "@type": "PostalAddress",
      addressLocality: organization.address.addressLocality,
      postalCode: organization.address.postalCode,
      streetAddress: organization.address.streetAddress,
      email: organization.email,
    },
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: organization.contacts.phones.map(({ number }) => number),
        contactType: "customer service",
      },
    ],
    logo: [
      {
        "@type": "ImageObject",
        id: "https://studycrumb.com/#logo",
        inLanguage: "en-US",
        url: "https://studycrumb.com/img/logo/logo-black.svg",
        contentUrl: "https://studycrumb.com/img/logo/logo-black.svg",
        width: 97,
        height: 44,
      },
    ],
  };

  const publisher = {
    "@type": "Organization",
    name: website.name,
    logo: {
      "@type": "ImageObject",
      url: "https://studycrumb.com/img/logo/logo-black.svg",
    },
  };

  const websiteData = {
    "@type": "WebSite",
    id: "https://studycrumb.com/#website",
    url: "https://studycrumb.com/",
    name: website.name,
    description: website.description,
    publisher,
  };

  const webPage: Record<string, any> = {
    "@context": "http://schema.org",
    "@type": pageType,
    id: "https://studycrumb.com/#webpage",
    url: canonicalUrl,
    name: pageTitle,
    description: pageDescription,
    inLanguage: "en-US",
    isPartOf: websiteData,

    ...extraFields,
  };

  if (metaThumbnail) {
    webPage.image = `https://${baseUrl + metaThumbnail}`;
  }

  if (metaData.datePublished) {
    webPage.datePublished = metaData.datePublished;
  }

  if (metaData.dateModified) {
    webPage.dateModified = metaData.datePublished;
  }

  const blogPage = {
    "@context": "https://schema.org",
    "@type": "Blog",
    name: pageTitle,
    description: pageDescription,
    url: canonicalUrl,
    publisher,
    author: {
      "@type": "Organization",
      name: website.name,
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://studycrumb.com${url}`,
    },
    ...extraFields,
  };

  const article = {
    "@context": "https://schema.org",
    "@type": "Article",
    id: "https://studycrumb.com/#article",
    headline: articleData?.heading,
    url: canonicalUrl,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": canonicalUrl,
    },
    image: articleData?.thumbnailUrl,
    name: pageTitle,
    description: pageDescription,
    datePublished: articleData?.publishedAt,
    dateModified: articleData?.updatedAt,
    author: {
      "@type": "Person",
      name: articleData?.author?.name,
      url: `https://studycrumb.com${articleData?.author?.url}`,
      description: articleData?.author?.description,
      image: articleData?.author?.avatarUrl,
    },
    publisher,
    wordCount: articleData?.wordCount,
    articleBody: articleData?.articleBody,
    inLanguage: "en-US",
    isPartOf: websiteData,
    ...extraFields,
  };

  const contactPage = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    name: metaData.title,
    description: metaData.description,
    url: canonicalUrl,
    mainEntity: {
      "@type": "Organization",
      name: "StudyCrumb",
      url: "https://studycrumb.com",
      logo: "https://studycrumb.com/img/logo/logo-black.svg",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: organization.contacts?.phones[0]?.number,
        contactType: "Customer Service",
        email: "support@studycrumb.com",
        areaServed: "Global",
        availableLanguage: "English",
      },
      address: {
        "@type": "PostalAddress",
        addressLocality: organization.address.addressLocality,
        postalCode: organization.address.postalCode,
        streetAddress: organization.address.streetAddress,
        email: organization.email,
      },
    },
  };

  const autorPage = {
    "@context": "https://schema.org",
    "@type": "Person",
    name: authorData?.name,
    url: canonicalUrl,
    jobTitle: "Educational Content Writer",
    disambiguatingDescription: authorData?.disambiguatingDescription,
    worksFor: {
      "@type": "Organization",
      name: "StudyCrumb",
      url: "https://studycrumb.com",
    },
    description: metaData.description,
    image: authorData?.image,
    ...extraFields,
  };

  const pricePage = {
    "@context": "https://schema.org",
    "@type": "Service",
    name: metaData.title,
    description: metaData.description,
    serviceType: "Writing",
    provider: {
      "@type": "Organization",
      name: "StudyCrumb",
      url: "https://studycrumb.com",
    },
    hasOfferCatalog: {
      "@type": "OfferCatalog",
      name: "Essay Writing Pricing",
      itemListElement: [
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Writing",
            description:
              "Creating original essays from scratch based on customer requirements, including research, argument development, and proper formatting and citation.",
          },
          url: "https://studycrumb.com/pricing",
        },
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Rewriting",
            description:
              "Rephrasing or reorganizing existing content to improve quality, flow, coherence and remove plagiarism while enhancing arguments. Up to 60%",
          },
          url: "https://studycrumb.com/pricing",
        },
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Editing",
            description:
              "Refining written content for clarity, accuracy, and quality, including grammar, punctuation, syntax, consistency, and coherence checks. Up to 30%",
          },
          url: "https://studycrumb.com/pricing",
        },
      ],
    },
  };

  return (
    <>
      {url === "/" && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationData) }}
          key="organization"
        />
      )}
      {isWebPage && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(webPage) }}
          key="webPage"
        />
      )}
      {isArticle && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(article) }}
          key="article"
        />
      )}
      {isBlog && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(blogPage) }}
          key="blog"
        />
      )}
      {isContact && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(contactPage) }}
          key="contact"
        />
      )}
      {isAuthor && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(autorPage) }}
          key="author"
        />
      )}
      {isPricePage && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(pricePage) }}
          key="price"
        />
      )}
      {metaReviews && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Product",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: metaReviews.ratingValue,
                reviewCount: metaReviews.reviewCount,
              },
              description: pageDescription,
              name: pageTitle,
              image: "https://studycrumb.com/img/thumbnail.png",
            }),
          }}
          key="reviews"
        />
      )}
      {articleReviews && articleReviews.ratingValue && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Product",
              name: pageTitle,
              aggregateRating: {
                "@type": "AggregateRating",
                bestRating: 5,
                ratingValue: articleReviews.ratingValue.toFixed(1),
                reviewCount: articleReviews.reviewCount,
              },
            }),
          }}
          key="reviews"
        />
      )}
    </>
  );
};
