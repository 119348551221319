import { MouseEvent } from "react";

type SpriteIconProps = {
  icon: React.SVGProps<SVGSVGElement>;
  className?: string;
  width?: number;
  height?: number;
  onClick?: (event: MouseEvent) => void;
};

export const SpriteIcon = ({ icon, className, width, height, onClick }: SpriteIconProps) => {
  const viewBox = icon.viewBox || "0 0 24 24";
  const viewBoxArr = viewBox.split(" ");
  const iconWidth = width || icon.width || viewBoxArr[2];
  const iconHeight = height || icon.height || viewBoxArr[3];

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      className={`icon ${className ? className : "icon-" + icon.id}`}
      viewBox={viewBox}
      onClick={onClick}
    >
      <use xlinkHref={`#${icon.id}`} />
    </svg>
  );
};
