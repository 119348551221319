export default function isCurrentPage(
  pathname: string,
  href: string,
  isBlogPage?: boolean,
  children?: {
    href: string;
  }[]
): boolean {
  const targetChild = children?.find(({ href }) => pathname.split("?")[0].trim() === href.trim());
  if (targetChild) return true;

  if (href === "/blog" && isBlogPage) return true;

  return pathname.split("?")[0].trim() === href.trim();
}
