import cn from "classnames";
import styles from "./Popover.module.scss";

type PopoverProps = {
  children: React.ReactNode;
  caption: string;
  soon?: boolean;
  burger?: boolean;
  footer?: boolean;
};

export const Popover = ({ children, caption, soon, burger, footer }: PopoverProps) => (
  <div className={cn(styles.popover, burger && styles.burger)}>
    <div
      className={cn(styles.caption, {
        [styles.soon]: soon,
        [styles.burger]: burger,
        [styles.footer]: footer,
      })}
    >
      {caption}
    </div>
    <div className={styles.body}>{children}</div>
  </div>
);
