import cn from "classnames";
import Link from "next/link";
import { AllHTMLAttributes, ReactNode, SVGProps, useMemo } from "react";
import type React from "react";
import s from "./Button.module.scss";
import { ButtonSpinner } from "components/UI/Buttons/ButtonSpinner";
import { IconImageType } from "@/types/icon";
import { IconImage } from "@/components/UI/IconImage";
import { SpriteIcon } from "@/components/UI/SpriteIcon";
import { LazySvgSpriteIcon } from "@/components/UI/LazySvgSpriteIcon";

export type ButtonProps = {
  type?: "accent" | "primary" | "white" | "link";
  size?: "xs" | "tiny" | "default" | "small" | "thin" | "fullwidth";
  outline?: boolean;
  icon?: SVGProps<SVGSVGElement> | IconImageType;
  prioritizeLoad?: boolean;
  iconPosition?: "prefix" | "suffix";
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  isExternalLink?: boolean;
  children?: ReactNode;
  className?: string;
  htmlType?: "button" | "submit" | "reset";
  noHover?: boolean;
  span?: boolean;
  iconSvg?: boolean;
} & Omit<AllHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, "size" | "span">;

export const Button = ({
  type = "accent",
  size = "default",
  outline,
  icon,
  iconPosition = "prefix",
  disabled,
  href,
  isExternalLink,
  children,
  className,
  htmlType,
  loading,
  span,
  noHover,
  iconSvg,

  prioritizeLoad,
  ...restProps
}: ButtonProps) => {
  const isLink = useMemo(() => href && !disabled, [href, disabled]);
  const isRegularLink = !!(isExternalLink || !!href?.match(/(order|dashboard)/));

  const Tag = useMemo(() => {
    if (isLink) {
      return "a";
    } else if (span) {
      return "span";
    } else {
      return "button";
    }
  }, [isLink, span]);

  const props = useMemo(
    () => ({ ...restProps, ...(isLink ? { href } : htmlType ? { type: htmlType } : {}) }),
    [href, htmlType, isLink, restProps]
  );

  const wrapperProps = useMemo(() => (isLink ? { href: href } : {}), [href, isLink]);

  const iconComponent = icon ? (
    typeof icon === "string" ? (
      iconSvg ? (
        <LazySvgSpriteIcon name={icon} />
      ) : (
        <IconImage size={24} icon={icon} priority={prioritizeLoad} className={s.iconImage} />
      )
    ) : (
      <SpriteIcon icon={icon} className={s.icon} />
    )
  ) : null;

  const buttonContents = (
    <>
      {icon && !loading && iconPosition === "prefix" && iconComponent}
      {loading && <ButtonSpinner className={s.spinner} />}
      <span className={s.inner}>{children}</span>
      {icon && !loading && iconPosition === "suffix" && iconComponent}
    </>
  );

  const classNames = cn(
    s.btn,
    s[type],
    s[`size-${size}`],
    {
      [s.outline]: outline,
      [s.disabled]: disabled,
      [s.withIcon]: !!icon,
      [s.iconBefore]: iconPosition == "prefix",
      [s.iconAfter]: iconPosition == "suffix",
      [s.iconOnly]: !children && icon,
      [s.noHover]: noHover,
    },
    className
  );

  return isLink && !isRegularLink ? (
    <Link className={classNames} {...props} href={href || "#"} prefetch={false}>
      {buttonContents}
    </Link>
  ) : (
    <Tag {...wrapperProps} className={classNames} {...props}>
      {buttonContents}
    </Tag>
  );
};
