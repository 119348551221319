"use client";
import classNames from "classnames";
import Link from "next/link";

import styles from "./FooterMenu.module.scss";

import isCurrentPage from "helpers/isCurrentPage";
import { useAppContext } from "@/helpers/contexts/AppContext";
import { usePathname } from "next/navigation";

export const FooterMenu = ({ isBlogPage }: { isBlogPage?: boolean }) => {
  const pathname = usePathname();

  const contextData = useAppContext();
  if (!contextData) return null;

  const {
    footer: { menu },
  } = contextData;

  return (
    <div className={styles.footerMenu}>
      {menu.map((menuItem, key) => (
        <div key={key} className={styles.footerMenuItem}>
          <h2 className={styles.menuTitle}>{menuItem.title}</h2>
          <ul className={styles.footerMenuList}>
            {menuItem.items.map((el, i) => (
              <li key={i} className={styles.item}>
                {/*{el.popover && (*/}
                {/*  <Popover caption={el.popover} soon footer>*/}
                {/*    <span className={classNames(styles.link, styles.disabled)}>{el.name}</span>*/}
                {/*  </Popover>*/}
                {/*)}*/}
                <Link
                  href={el.href}
                  rel={el.rel}
                  prefetch={false}
                  className={classNames(styles.link, {
                    [styles.linkActive]: isCurrentPage(pathname, el.href, isBlogPage),
                  })}
                >
                  {el.name}
                </Link>
                {/*{el.items && !el.popover && (*/}
                {/*  <div>*/}
                {/*    <DropdownLinks btnText={el.name} items={el.items} />*/}
                {/*  </div>*/}
                {/*)}*/}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
