"use client";
import { createContext, useContext } from "react";
import { AppProps as NextAppProps } from "next/app";
import { LegalInfoData } from "@/types/strapi/global/legalInfo";
import { StrapiGlobalData } from "@/types/strapi/global";
import { AdvertingCampaign } from "@/types/shared";

export type AppCustomProps = {
  legalInfo: LegalInfoData;
  headerMenu: StrapiGlobalData["headerMenu"];
  advertisingCampaign: AdvertingCampaign | null;
  setAdvertisingCampaign: (value: AdvertingCampaign | null) => void;
  footer: StrapiGlobalData["footer"];
};

export type AppProps = NextAppProps & AppCustomProps;

export type AppContextType = AppCustomProps | null;

export const AppContext = createContext<AppContextType>(null);

export const useAppContext = () => useContext(AppContext);
