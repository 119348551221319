import { useState } from 'react';
import { useEventListener } from './useEventListener';

export const useInteraction = () => {
  const [isInteracted, setIsInteracted] = useState(false);
  const interactedCheck = () => !isInteracted && setIsInteracted(true);

  useEventListener('scroll', interactedCheck);
  useEventListener('mousemove', interactedCheck);

  if (isInteracted) {
    removeEventListener('scroll', interactedCheck);
    removeEventListener('mousemove', interactedCheck);
  }

  return { isInteracted };
};
