import { IconImageType } from "@/types/icon";

export const SLUG_IMG_MAP: Record<string, IconImageType> = {
  "/what-is-studycrumb": "file",
  "/how-it-works": "gear",
  "/faq": "faq",
  "/contacts": "email",
  "/dissertation-writing-service": "dissertation-writing-service",
  "/thesis-writing-service": "thesis-writing-service",
  "/term-paper-writing-service": "term-paper-writing-service",
  "/research-paper-writing-service": "research-paper-writing-service",
  "/speech-writing-service": "speech-writing-service",
  "/powerpoint-presentation-writing-service": "presentation-writing-service",
  "/write-my-assignment": "write-my-assignment",
  "/case-study-writing-service": "case-study-writing-service",
  "/capstone-project-writing-service": "capstone-project-writing-service",
  "/book-report-writing-service": "book-report-writing-service",
  "/personal-statement-writing-service": "personal-statement-writing-service",
  "/admission-essay-writing-service": "admission-essay-writing-service",
  "/scholarship-essay-writing-service": "scholarship-essay-writing",
  "/college-essay-writing-service": "college-essay-writing-service",
  "/literature-review-writing-service": "literature-review-writing-service",
  "/write-my-lab-report": "write-my-lab-report",
  "/do-my-homework": "do-my-homework",
  "/buy-essay": "buy-essay",
  "/order-essay": "order-essay",
  "/tools": "all-tools",
  "/assignment-calendar": "assignment-calendar",
  "/words-to-pages-converter": "words-to-pages-converter",
  "/title-page-generator": "title-page-generator",
  "/conclusion-generator": "conclusion-generator",
  "/thesis-statement-generator": "thesis-statement-generator",
  "/essay-title-generator": "essay-title-generator",
  "/alphabetizer": "alphabetizer",
  "/grade-calculator": "grade-calculator",
  "/case-converter": "case-converter",
  "/grammar-checker": "grammar-checker",
  "/plagiarism-checker": "plagiarism-checker",
  "/summarizer": "summarizer",
  "/paraphrasing-tool": "paraphrasing-tool",
  "/word-counter": "word-counter",
  "/readability-checker": "readability-checker",
  "/essay-maker": "essay-maker",
  "/essay-typer": "essay-typer",
  "/free-paper-grader": "paper-grader",
  "/spell-checker": "spell-checker",
  "/coursework-writing-service": "coursework-writing-service",
};
