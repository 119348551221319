import packageInfo from "./package.json";

export const SITE_NAME = "StudyCrumb.com";

const { version } = packageInfo;

export const APP_VERSION = version;

// TODO: Debug NEXT_* env & Storybook
export const baseUrl = process.env.NEXT_PUBLIC_DOMAIN || "studycrumb.devstage.cloud"; // FIXME: Remove hardcoded domain
export const cmsUrl = process.env.NEXT_PUBLIC_STRAPI_API_URL;

export const orderFormUrl = process.env.NEXT_PUBLIC_API_CUSTOMER_URL || "";

export const api = {
  contacts: `https://api.${baseUrl}/contactus`,
};
export const isStage = !!baseUrl?.match(/devstage.cloud/);
export const orderLink = `https://app.${baseUrl}/order`;
export const ordersLink = `https://app.${baseUrl}/orders`;

export const SERVICE_LASTMOD_DATE = "2023-10-11T00:00:00.000Z";

export const DASHBOARD_LASTMOD_DATE = "2023-10-11T00:00:00.000Z";

export const CHATWOOT_TOKEN = "34x142QzfL6AszjZUC8UHVkD";

export const GA_CODE = process.env.NEXT_PUBLIC_GA_CODE; // Google Analytics code (MEASUREMENT ID)

export const TOOL_SLUGS = [
  "alphabetizer",
  "assignment-calendar",
  "conclusion-generator",
  "thesis-statement-generator",
  "grade-calculator",
  "case-converter",
  "grammar-checker",
  "plagiarism-checker",
  "summarizer",
  "paraphrasing-tool",
  "word-counter",
  "readability-checker",
  "essay-maker",
  "essay-typer",
  "free-paper-grader",
  "spell-checker",
  "words-to-pages-converter",
  "title-page-generator",
  "essay-title-generator",
] as const;

export const ARTICLES_PER_PAGE = 12;

export const BLOG_DEFAULT_ARTICLES_PER_PAGE = 24;

export const WRITERS_PER_PAGE = 8;

// tools
export const SPELL_CHECKER_API_URL = "https://essay-toolbox.sky-services.cc/api/spell_checker/create";

export const READABILITY_CHECKER_API_URL =
  "https://readability.sky-services.cc/api/readability_generator/create";

export const GRAMMAR_CHECKER_API_URL = "https://essay-toolbox.sky-services.cc/api/grammar_generator/create";

export const ALPHABETIZER_API_URL =
  "https://alphabetizer-api.sky-services.cc/api/alphabetizer_generator_list/create";

export const SUMMARIZER_API_URL = "https://essay-toolbox.sky-services.cc/api/conclusion_generator/create";

export const ESSAY_MAKER_API_URL = "https://essay-maker.sky-services.cc/api/v1/text/suggestions";

export const TITLE_GENERATOR_API_URL =
  "https://essay-topic-generator.sky-services.cc/api/essaytopic_generator/create";

export const THESIS_GENERATOR_API_URL = "https://essay-toolbox.sky-services.cc/api/thesis_generator/create";

export const CONCLUSION_GENERATOR_API_URL =
  "https://essay-toolbox.sky-services.cc/api/conclusion_generator/create";

export const PLAG_CHECK_API_URL = "https://essaytools-api.sky-services.cc/api";

export const PARAPHRASING_API_URL =
  "https://paraphrasing-tool.sky-services.cc/api/paraphrasing_generator/create";

export const TITLE_PAGE_API_URL = "https://essay-toolbox.sky-services.cc/";

// Cache tags
export const GLOBAL_DATA_TAG = "global";
export const ALL_TOOLS_TAG = "allTools";
export const ENTITY_TAG = "entity_";
export const ALL_CATEGORIES_TAG = "categories";

export const BLOG_PAGE_TAG = "blogPage";

export const ALL_AUTHORS_TAG = "authors";

export const AUTHOR_TAG = "author_";

export const WRITER_TAG = "writer_";

export const SUBCATEGORY_TAG = "subcategory_";
